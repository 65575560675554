<template>
    <base-button
        :form="buttonForm"
        :view="buttonView"
        :class="{'button-list__item_right': buttonIsRigth}" 
        class="button-list__item"
        @click="onClick"
    >
        {{buttonText}}
    </base-button>
</template>

<script>
/** Компонент базового элемента списка кнопок */
import BaseButton from './BaseButton';
export default {
    name: 'BaseButtonListItem',
    components: {BaseButton},
    /**
     * Входные данные компонента
     * @property {Object} buttonListItem - элемент списка кнопок
     */
    props: {
        buttonListItem: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        /**
         * Идентификатор кнопки
         * @returns {String}
         */
        buttonId() {
            const {id = '-1'} = this.buttonListItem;
            return String(id);
        },
        /**
         * Тип отображения базовой кнопки (primary, secondary, success, cancel, border, simple)
         * @returns {String}
         */
        buttonView() {
            const {view = 'primary'} = this.buttonListItem;
            return String(view);
        },
        /**
         * Текст кнопки
         * @returns {String}
         */
        buttonText() {
            const {text = ''} = this.buttonListItem;
            return String(text);
        },
        /**
         * Форма базовой кнопки (oval, square)
         * @returns {String}
         */
        buttonForm() {
            const {form = 'square'} = this.buttonListItem;
            return String(form);
        },
        /**
         * Позиционирование кнопки в спике (left, right)
         * @returns {String}
         */
        buttonPosition() {
            const {position = 'left'} = this.buttonListItem;
            return String(position);
        },
        /**
         * Отпозиционировать ли справа
         * @returns {Boolean}
         */
        buttonIsRigth() {
            return this.buttonPosition === 'right';
        },
        /**
         * Название эмита для кнопки
         * @returns {String}
         */
        buttonEmitName() {
            const {emitName = ''} = this.buttonListItem;
            return String(emitName);
        }
    },
    methods: {
        /**
         * Событие клика по кнопке
         */
        onClick() {
            const emitName = this.buttonEmitName === '' ? 'onClick' : this.buttonEmitName;
            this.$emit('onClick', emitName, this.buttonId);
        }
    }
}
</script>

<style lang="scss" scoped>
    .button-list__item {
        margin: 8px 10px;
        &_right {
           margin-left: auto; 
        }
    }
</style>