<template>
    <div>
        <base-button-list
            :buttonList="actionList"
            @onDownload="onDownload"
            @onCreate="onCreate"
            @onHistory="onHistory"
            @onBack="onBack"
        />
        <div class="reconciliation__table-container">
            <app-table
                :columns="tableColumns"
                :rows="tableRows"
                primaryKey="id"
                class="reconciliation__table"
                @search="onFilterTable"
                @sort="onSortTable"
            >
                <template v-slot:actionRemove="scopeData">
                    <div
                        class="reconciliation__table-actions"
                        @click="onRemoveTableRow(scopeData)"
                    >
                        <base-icon icon="icon-delete" color="#ADB0BB" style="font-size: 14px;" />
                    </div>
                </template>
                <template v-slot:actionEdit="scopeData">
                    <div
                        class="reconciliation__table-actions"
                        @click="onEditTableRow(scopeData)"
                        v-if="scopeData.row.fileId"
                    >
                        <base-icon icon="icon-edit" color="#ADB0BB" style="font-size: 14px;" />
                    </div>
                </template>
            </app-table>
        </div>
    </div>
</template>

<script>
/** Лайаут для стартовой работы со сверками и просмотра истории импорта */
import BaseIcon from '@/components/Base/BaseIcon';
import BaseButtonList from '@/components/Base/BaseButtonList';
import AppTable from '@/components/Table2/Table';
import ServiceReconciliationTable from '@/services/ServicesReconciliation/ServiceReconciliationTable';
export default {
    name: 'LayoutReconciliationStart',
    components: {
        BaseIcon,
        BaseButtonList,
        AppTable
    },
    /**
     * Входные данные компонента
     * @property {Array} actionList - список кнопок с действиями
     * @property {String} modePage - режим страницы
     */
    props: {
        actionList: {
            type: Array,
            default: () => ([])
        },
        modePage: {
            type: String,
            default: ''
        }
    },
    /**
     * Данные компонента
     * @property {Array} tableColumns - колонки таблицы
     * @property {Array} tableRows - строки таблицы
     * @property {Array} tableRowsBuff - буффер строк таблицы
     * @property {Object} tableSortData - данные сортировки таблицы
     */
    data: () => ({
        tableColumns: [],
        tableRows: [],
        tableRowsBuff: [],
        tableSortData: {}
    }),
    mounted() {
        /** Запрашиваем данные для таблицы*/
        this.getDataTable();
    },
    methods: {
        /**
         * Получить данные таблицы
         */
        getDataTable() {
            ServiceReconciliationTable.getData(this.modePage, (tableColumns, tableRows) => {
                this.tableColumns = tableColumns;
                this.tableRows = tableRows;
                this.tableRowsBuff = Object.assign([], this.tableRows);
            });
        },
        /**
         * Событие фильтрации таблицы
         * @param {Object} filterData - данные фильтрации
         */
        onFilterTable(filterData = {}) {
            const rowsFiltered = ServiceReconciliationTable.filter(this.tableRowsBuff, filterData);
            this.tableRows = rowsFiltered;
            this.onSortTable(this.tableSortData);
        },
        /**
         * Событие сортировки таблицы
         * @param {Object} sortData - данные сортировки
         */
        onSortTable(sortData = {}) {
            if (Object.keys(sortData).length !== 0) {
                this.tableSortData = sortData;
                const rowsSorted = ServiceReconciliationTable.sort(this.tableRows, sortData);
                this.tableRows = rowsSorted;
            }
        },
        /**
         * Событие удаления строки таблицы
         * @param {Object} scopeData - данные области видимости события (колонка, строка)
         */
        onRemoveTableRow(scopeData = {}) {
            const {row = {}} = scopeData;
            const {id = '-1'} = row;
            ServiceReconciliationTable.remove(this.modePage, id, (reconciliationId) => {
                const tableRowIndex = this.tableRows.findIndex(row => {
                    const {id: rowId = '-1'} = row;
                    return String(rowId) === String(reconciliationId);
                });
                const tableRowBuffIndex = this.tableRowsBuff.findIndex(row => {
                    const {id: rowId = '-1'} = row;
                    return String(rowId) === String(reconciliationId);
                });
                if (tableRowIndex !== -1 && tableRowBuffIndex !== -1) {
                    this.tableRows.splice(tableRowIndex, 1);
                    this.tableRowsBuff.splice(tableRowBuffIndex, 1);
                }
            });
        },
        /**
         * Событие редактирования шаблона
         * @param {Object} scopeData - данные области видимости события (колонка, строка)
         */
        onEditTableRow(scopeData = {}) {
            this.$router.push({path: '/reconciliation?file=' + scopeData.row.fileId + '&preset=' + scopeData.row.id});
        },
        /**
         * Событие перехода к загрузке сверок
         */
        onDownload() {
            this.$router.push({name: 'reconciliationDownload'});
        },
        /**
         * Событие перехода к созданию шаблонов
         */
        onCreate() {
            this.$router.push({name: 'reconciliationTemplate'});
        },
        /**
         * Событие перехода к просмотру истории испорта
         */
        onHistory(){
            this.$router.push({name: 'reconciliationHistory'});
        },
        /**
         * Событие перехода назад
         */
        onBack(){
            this.$router.push({name: 'reconciliationStart'});
        }
    }
}
</script>

<style lang="scss" scoped>
    .reconciliation__table {
        // padding-top: 61px !important;
        &-container {
            margin-top: 8px;
            position: absolute;
            height: 85%;
            width: 95%;
        }
        &-actions {
            cursor: pointer;
        }
    }
</style>
