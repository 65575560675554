import ServiceApi from '../ServiceApi';
import ServiceBaseTableClient from '../ServicesBase/ServiceBaseTableClient';
/** Сервис для таблицы начальной страницы сверок */
export default class ServiceReconciliationTable {
    /**
     * Маршурты для запросов
     * @static
     * @property {Object} getTable - маршруты для получения таблицы
     * @property {Object} remove - маршруты для удаления строки в таблице
     */
    static requestRouts = {
        getTable: {
            start: '/preset/default/list',
            history: '/upload/default/files-list'
        },
        remove: {
            start: '/preset/default/delete',
            history: '/upload/default/rollback-file'
        }
    }
    /**
     * Получить данные шаблонов
     * @static
     * @async
     * @param {String} modePage - режим страницы
     * @param {Function} actionAfter - событие, сработающее после получения данных
     */
    static async getData(modePage = '', actionAfter = () => {}) {
        const requestRoute = this.requestRouts.getTable[modePage] || '';
        try {
            const res = await ServiceApi.post('reconciliation', requestRoute);
            const {data = {}} = res;
            const {data: dataTable = {}} = data;
            const {tableColumns = [], tableRows = []} = dataTable;
            const tableColumnsAdapted = this.adapterColumns(tableColumns);
            actionAfter(tableColumnsAdapted, tableRows);
        }
        catch(error) {
            console.log(error);
        }
    }
    /**
     * Удалить строку из таблицы
     * @param {String} modePage - режим страницы
     * @param {String} id - идентификатор для запроса на удаление
     * @param {Function} actionAfter - событие, сработающее после получения данных
     */
    static async remove(modePage = '', id = '', actionAfter = () => {}) {
        const postData = modePage === 'start' ? {presetId: id} : {fileId: id};
        const requestRoute = this.requestRouts.remove[modePage] || '';
        try {
            const res = await ServiceApi.post('reconciliation', requestRoute, postData);
            const {data = {}} = res;
            const {data: dataRemove = {}} = data;
            const {result = false} = dataRemove;
            if (result) {
                actionAfter(id);
            }
        }
        catch(error) {
            console.log(error);
        }
    }
    /**
     * Адаптер для колонок таблицы
     * @static
     * @param {Array} tableColumns - колонки таблицы
     * @returns {Array}
     */
    static adapterColumns(tableColumns = []) {
        let tableColumnsAdapted = tableColumns.map(column => {
            const columnAdapted = {
                ...column,
                canSearch: true,
                canSorted: true,
                columnStyles: {flexGrow: 1}
            };
            return columnAdapted;
        });
        const columnActionRemove = {
            prop: 'actionRemove',
            label: '',
            isSlot: true,
            columnStyles: {
                width: '5px'
            }
        };
        const columnActionEdit = {
            prop: 'actionEdit',
            label: '',
            isSlot: true,
            columnStyles: {
                width: '5px'
            }
        };
        tableColumnsAdapted.push(columnActionEdit);
        tableColumnsAdapted.push(columnActionRemove);
        return tableColumnsAdapted;
    }
    /**
     * Сортировка строк
     * @static
     * @param {Array} rows - строки
     * @param {Object} sortData - данные сортировки
     * @returns {Array}
     */
    static sort(rows = [], sortData = {}) {
        return ServiceBaseTableClient.sort(rows, sortData);
    }
    /**
     * Фильтрация строк
     * @static
     * @param {Array} rows - строки
     * @param {Object} filterData - данные фильтрации
     * @returns {Array}
     */
    static filter(rows = [], filterData = {}) {
        return ServiceBaseTableClient.filter(rows, filterData);
    }
}
