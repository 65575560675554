<template>
    <div class="button-list">
        <base-button-list-item
            v-for="(buttonItem, buttonItemIndex) in buttonList"
            :key="buttonItemIndex"
            :buttonListItem="buttonItem"
            @onClick="onClick"
        />
    </div>
</template>

<script>
/** Компонент базового списка кнопок */
import BaseButtonListItem from './BaseButtonListItem';
export default {
    name: 'BaseButtonList',
    components: {BaseButtonListItem},
    /**
     * Входные данные компонента
     * @property {Array} buttonList - список кнопок
     */
    props: {
        buttonList: {
            type: Array,
            default: () => ([])
        }
    },
    methods: {
        /**
         * Событие клика по кнопке
         * @param {String} emitName - название эмита
         * @param {String} buttonId - идентификатор кнопки
         */
        onClick(emitName = '', buttonId = '-1') {
            this.$emit(emitName, buttonId);
        }
    }
}
</script>

<style lang="scss" scoped>
    .button-list {
        display: flex
    }
</style>