/** Базовый сервис для работы с таблицей на клиенте */
export default class ServiceBaseTableClient {
    /**
     * Сортировка строк
     * @static
     * @param {Array} rows  - строки
     * @param {Object} sortData - данные сортировки
     * @returns {Array}
     */
    static sort(rows = [], sortData = {}) {
        if (Object.keys(sortData).length !== 0) {
            const {key = '', type = 'asc'} = sortData;
            rows.sort((rowA, rowB) => {
                const strA = String(rowA[key]) || '';
                const strB = String(rowB[key]) || '';
                const compareRes = strA.localeCompare(strB);
                return type === 'desc' ? Number(compareRes) * -1 : Number(compareRes);
            });
            return rows;
        }
    }
    /**
     * Фильтрация строк
     * @static
     * @param {Array} rows  - строки
     * @param {Object} filterData - данные фильтрации
     * @returns {Array}
     */
    static filter(rows = [], filterData = {}) {
        const rowsFiltered = rows.filter((row) => {
            const isFiltered = Object.keys(filterData).every(key => {
                const cellValue = String(row[key]) || '';
                const str = cellValue.toLowerCase();
                const strSub = String(filterData[key]).toLowerCase();
                return str.includes(strSub);
            });
            return isFiltered;
        });
        return rowsFiltered;
    }
}